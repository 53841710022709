import "./index.css"

const StatusBar = (props) => {
    return (
        <div className="status-bar-container">
            <div className="status-bar-wrapper">
                <div className="status-bar-header">
                    <h4 className="m-0">Data Stream</h4>
                </div>
                <div className="data-stream-display">
                    <span className="data-stream-text-line">Establishing Connection</span>
                    <span className="data-stream-text-line">Success!</span>
                </div>
            </div>
        </div>
    )
}

export default StatusBar