
import Chart from "chart.js/auto"
import "./index.css"
import { FormGroup, Switch, MenuItem, Button, Card, Menu, InputGroup } from "@blueprintjs/core";
import { Select, MultiSelect, } from "@blueprintjs/select";
import { DateRangeInput3 } from "@blueprintjs/datetime2";
import { useEffect, useState } from "react";
import "chartjs-adapter-date-fns"
import axios from "axios";
import { param } from "jquery";

function TemperatureGraph(props) {

    const projects = props.projects;
    const [selectedProject, setSelectedProject] = useState(projects.length > 0 ? projects[0] : "Error, no projects");
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [data, setData] = useState(null);
    const [tideData, setTideData] = useState([])
    const [tidePredictionData, setTidePredictionData] = useState([])
    const [chart, setChart] = useState(null);
    const [timezone, setTimezone] = useState("UTC");
    const [startDate, setStartDate] = useState(new Date(Date.now() - 604800000));
    const [endDate, setEndDate] = useState(new Date());

    const backend_url = process.env.REACT_APP_BACKEND_URL

    const parametersInit = [
        {
            name: "temperature",
            enabled: true,
            axis_position: 'left',
            show_axis_ticks: false,
            unitOptions: ["PPM"]
        },
        {
            name: "tides",
            enabled: false,
            axis_position: 'left',
            show_axis_ticks: false,
        },
        {
            name: "tidePrediction",
            enabled: false,
            axis_position: 'left',
            show_axis_ticks: false,
        },
    ]

    const [parameters, setParameters] = useState(parametersInit)

    function getData(start, end) {
        if (!props.jwt || projects.length === 0) {
            return;
        }

        var serialize_sensors = "";
        console.log(projects)
        projects.map((project) => {
            if (project.temp_sensors) {
                console.log(project)
                project.temp_sensors.map((sensor) => serialize_sensors += sensor + ",")
            }
        })
        console.log(serialize_sensors)

        axios.get(
            backend_url + "/api/temperature-data",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                },
                params: {
                    serials: serialize_sensors,
                    start: start,
                    end: end
                }
            }
        )
            .then((res) => {
                const sorted = res.data.sort((a, b) => a.timestamp - b.timestamp)
                setData(sorted)
                console.log("sorted", sorted)
            })
            .catch((error) => {
                console.log("error getting pco2 data")
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
                else {
                    console.log(error);
                }
                return
            })

            axios.get(backend_url + "/api/tide-data",
                {
                    params: {
                        start: start,
                        end: end
                    }
                }
            )
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response)
                        console.log(error.response.status)
                        console.log(error.response.headers)
                    }
                    return
                })
                .then((res) => {
                    if(res) {
                        setTideData(res.data)
                    }
                })
    
            axios.get(backend_url + "/api/tide-prediction",
                {
                    params: {
                        start: start,
                        end: end
                    }
                }
            )
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response)
                        console.log(error.response.status)
                        console.log(error.response.headers)
                    }
                    return
                })
                .then((res) => {
                    setTidePredictionData(res.data)
                })
    }

    function setup() {
        const now = Math.floor(Date.now() / 1000);
        const weekAgo = now - 604800;
        getData(weekAgo, now);
    }

    useEffect(setup, [props.projects]);

    const pco2SelectProps = {
        filterable: false,
        fill: true,
        popoverProps: {
            minimal: true
        }
    };

    function renderProjectItems(val, props) {
        return (
            <MenuItem
                key={val.project_name}
                text={val.project_name}
                onClick={(e) => {
                    setSelectedProject(val)
                    setSelectedSensors(val.temp_sensors ? val.temp_sensors : [])
                }
                }>
            </MenuItem>
        )
    }

    function rendertimezoneItems(val, props) {
        return (
            <MenuItem
                key={val}
                text={val}
                onClick={(e) => {
                    setTimezone(val)
                }}>
            </MenuItem>
        )
    }

    function onClickSensor(evt) {
        onSelectSensor(evt.target.textContent);
    }

    function onSelectSensor(val) {
        if (!selectedSensors.includes(val)) {
            setSelectedSensors([...selectedSensors, val])
        }
    }

    function onRemoveSensor(val) {
        setSelectedSensors(selectedSensors.filter((s) => s !== val))
    }

    function onChangeSelectParam(val) {
        const newParams = parameters.map((p) => {
            if (p === val) {
                return { ...p, enabled: !p.enabled }
            }
            else {
                return p
            }
        })
        setParameters(newParams)
    }

    function onChangeParamAxis(val) {
        const newParams = parameters.map((p) => {
            if (p === val) {
                return { ...p, axis_position: val.axis_position === "left" ? "right" : "left" }
            }
            else {
                return p
            }
        })
        setParameters(newParams)
    }

    function onChangeParamTicks(val) {
        const newParams = parameters.map((p) => {
            if (p === val) {
                return { ...p, show_axis_ticks: !val.show_axis_ticks }
            }
            else {
                return p
            }
        })
        setParameters(newParams)
    }

    function renderParameter(val, itemProps) {
        return (
            <MenuItem
                key={val.name}
                text={val.name}
                onClick={onClickParameter}
                small={true}
                shouldDismissPopover={false}>
                <MenuItem
                    className="parameter-submenu-item"
                    shouldDismissPopover={false}
                    {...pco2SelectProps}
                    text="Enabled"
                    labelElement={<Switch
                        checked={val.enabled}
                        className="parameter-submenu-item"
                        onChange={() => onChangeSelectParam(val)}>
                    </Switch>}>
                </MenuItem>
                <MenuItem
                    className="parameter-submenu-item"
                    text="Toggle Axis Side:"
                    shouldDismissPopover={false}
                    {...pco2SelectProps}
                    labelElement={val.axis_position === "left" ? "left" : "right"}
                    onClick={() => onChangeParamAxis(val)}>
                </MenuItem>
                <MenuItem
                    className="parameter-submenu-item"
                    text="Toggle Axis Ticks:"
                    shouldDismissPopover={false}
                    {...pco2SelectProps}
                    labelElement={val.show_axis_ticks ? "on" : "off"}
                    onClick={() => onChangeParamTicks(val)}>
                </MenuItem>
                {
                    /*
                    <MenuItem
                className="parameter-submenu-item"
                text="Units"
                shouldDismissPopover={false}
                {...pco2SelectProps}
                labelElement={"test"}>
            </MenuItem>
            <MenuItem
                className="parameter-submenu-item"
                text="Filters"
                shouldDismissPopover={false}
                {...pco2SelectProps}
                labelElement={"test"}>
            </MenuItem>
                    */
                }
            </MenuItem >
        )
    }

    function onSelectParameter(val) {
        if (!val.enabled) {
            // setSelectedParameters([...selectedParameters, val])
        }
    }

    function onClickParameter(evt) {
        onSelectParameter(evt.target.textContent)
    }

    function onRemoveParameter(val) {
        const newParams = parameters.map((p) => {
            if (p.name === val) {
                return { ...p, enabled: false }
            }
            else return p
        })
        setParameters(newParams)
    }

    function onDateRangeChange(range) {
        if (!range.length == 2) {
            return
        }
        setStartDate(range[0])
        setEndDate(range[1])
    }

    function formatDate(d) {
        /*
        Convert Date object into a string for rendering chart, depending on if
        in local time or UTC time
        */
        function padLeading0(num) {
            return num > 9 ? "" + num : "0" + num
        }

        var t;
        if (timezone === "UTC") {
            //amusing bug when forgetting to pad leading 0 of hour, data during the morning (12:00 - 9:59) disappears
            //and switching between UTC and local time makes it seem like data is teleporting back and forth between
            //times the sensor was off
            t = `${d.getUTCFullYear()}-${padLeading0(d.getUTCMonth() + 1)}-${padLeading0(d.getUTCDate())} ${padLeading0(d.getUTCHours())}:${padLeading0(d.getUTCMinutes())}`
        }
        else {
            t = `${d.getFullYear()}-${padLeading0(d.getMonth() + 1)}-${padLeading0(d.getDate())} ${padLeading0(d.getHours())}:${padLeading0(d.getMinutes())}`
        }

        return t;
    }

    function buildChart() {
        if (!data) {
            return
        }
        if (chart) {
            chart.destroy();
        }

        const chartDatasets = {}
        selectedSensors.map((sensor) => chartDatasets[sensor] = {
            temperature_data: []
        })

        console.log("datasets", chartDatasets)

        data.map((point) => {
            const t = point.timestamp * 1000;

            if (selectedSensors.includes("" + point.sensor_id)) {
                chartDatasets[point.sensor_id].temperature_data.push(
                    {
                        x: t,
                        y: point.temperature
                    }
                )
            }
        })

        //figure out which parameters to include
        const selectedParameters = {}
        const x_min = startDate.valueOf()
        const x_max = endDate.valueOf()
        const scales = {
            x: {
                type: "time",
                min: x_min,
                max: x_max,
                time: {

                    parser: function (timestamp) {
                        if (timezone === "UTC") {
                            return timestamp;
                        }
                        else {
                            const offset = new Date().getTimezoneOffset(); //offset in minutes
                            
                            return timestamp + (offset * 60 * 1000);
                        }

                    }
                }

            }
        }
        parameters.map((p) => {
            selectedParameters[p.name] = p
        })

        const renderDatasets = []
        Object.keys(chartDatasets).map((key) => {

            if (selectedParameters["temperature"].enabled) {
                renderDatasets.push({
                    label: key + ":temperature",
                    yAxisID: "temperature_y",
                    data: chartDatasets[key].temperature_data,
                    spanGaps: 1000 * 60 * 60
                })
                scales["temperature_y"] = {
                    type: 'linear',
                    position: selectedParameters["temperature"].axis_position,
                    grid: {
                        drawOnChartArea: selectedParameters["temperature"].show_axis_ticks, // only want the grid lines for one axis to show up
                    },
                    title: {
                        display: true,
                        text: "SCD Temp °C",
                    }
                }
            }
        })

        if (selectedParameters["tides"].enabled) {
            renderDatasets.push({ 
                label: "Tides", 
                yAxisID: "tide_y",
                fill: true, 
                data: tideData
            })
            scales["tidy_y"] = {
                type: 'linear',
                position: selectedParameters["tides"].axis_position,
                grid: {
                    drawOnChartArea: selectedParameters["tides"].show_axis_ticks, // only want the grid lines for one axis to show up
                },
                title: {
                    display: true,
                    text: "Humidity %RH",
                }
            }
        }

        if (selectedParameters["tidePrediction"].enabled) {
            renderDatasets.push({ 
                label: "Tide Predictions", 
                yAxisID: "tide_prediction_y",
                data: tidePredictionData
            })
            scales["tide_prediction_y"] = {
                type: 'linear',
                position: selectedParameters["tidePrediction"].axis_position,
                grid: {
                    drawOnChartArea: selectedParameters["tidePrediction"].show_axis_ticks, // only want the grid lines for one axis to show up
                },
                title: {
                    display: true,
                    text: "Humidity %RH",
                }
            }
        }

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            borderWidth: 2,
            animation: {
                duration: 0
            },
            plugins: {
                title: {
                    display: true,
                },
                legend: {
                    display: true
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            stacked: false,
            scales: scales
        }

        const canvas = document.getElementById("temperature-graph-canvas");
        const context = canvas.getContext("2d");

        const newChart = new Chart(
            context,
            {
                type: 'line',
                data: {
                    datasets: renderDatasets
                },
                options: chartOptions
            }
        )
        setChart(newChart)
    }

    useEffect(buildChart, [selectedProject, selectedSensors, timezone, startDate, endDate, parameters])

    return (
        <div className="details-graph-container">
            <Card className="details-graph-options-container">
                <h3 className="bp5-heading">{props.title ? props.title : "Unknown Error Loading Data..."}</h3>
                <FormGroup
                    label="Project:"
                    inline={true}>
                    <Select
                        {...pco2SelectProps}
                        items={projects}
                        itemRenderer={renderProjectItems}>
                        <Button alignText="left" fill={true} rightIcon="caret-down" text={selectedProject.project_name}></Button>
                    </Select>
                </FormGroup>
                <FormGroup
                    fill={true}
                    label="Sensors:">
                    <MultiSelect
                        {...pco2SelectProps}
                        items={selectedProject.temp_sensors ? selectedProject.temp_sensors : ["No Pco2 sensors attached to this project"]}
                        selectedItems={selectedSensors}
                        itemRenderer={(val, itemProps) => {
                            return (
                                <MenuItem
                                    key={val}
                                    text={val}
                                    onClick={onClickSensor}>
                                </MenuItem>
                            )
                        }}
                        tagRenderer={(val) => val}
                        onRemove={onRemoveSensor}
                        filterable={true}>
                    </MultiSelect>
                </FormGroup>
                <FormGroup
                    fill={true}
                    label="Parameters:">
                    <MultiSelect
                        {...pco2SelectProps}
                        items={parameters}
                        selectedItems={parameters.filter((p) => p.enabled).map((p) => p.name)}
                        itemRenderer={renderParameter}
                        tagRenderer={(val) => val}
                        onRemove={onRemoveParameter}>
                    </MultiSelect>
                </FormGroup>
                <FormGroup
                    fill={true}
                    label="Date Selection:">
                    <DateRangeInput3
                        fill={true}
                        onChange={onDateRangeChange}>
                    </DateRangeInput3>
                </FormGroup>
                <FormGroup
                    label="Timezone Select"
                    inline="true"
                >
                    <Select
                        {...pco2SelectProps}
                        items={["UTC", "Local Timezone"]}
                        itemRenderer={rendertimezoneItems}>
                        <Button alignText="left" fill={true} rightIcon="caret-down" text={timezone}></Button>
                    </Select> 
                </FormGroup>
                <i style={{"fontSize": "x-small"}}>Local Timezone is the timezone detected from this computer, not at the sensor deployment site</i>
                <FormGroup
                    label="Disable Calibration"
                    inline="true">
                    <Switch style={{ "marginTop": "3.5px" }}></Switch>
                </FormGroup>
                <hr />
                <h4 className="bp5-heading">Live Data: TODO</h4>
                <div className="details-graph-live-readings">
                    <table className="bp5-html-table w-100">
                        <thead>
                            <tr>
                                <th>Sensor</th>
                                <th>Value</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>test</td>
                                <td>23.1 ppm</td>
                                <td>15s</td>
                            </tr>
                            <tr>
                                <td>test</td>
                                <td>23.1 ppm</td>
                                <td>15s</td>
                            </tr>
                            <tr>
                                <td>test</td>
                                <td>23.1 ppm</td>
                                <td>15s</td>
                            </tr>
                            <tr>
                                <td>test</td>
                                <td>23.1 ppm</td>
                                <td>15s</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card className="details-graph-chart-container">
                <canvas id="temperature-graph-canvas"></canvas>
            </Card>
        </div>
    )
}

export default TemperatureGraph;
