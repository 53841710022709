import { useEffect, useState } from "react";
import axios from "axios";
import logo from "../../res/logo.png"
import "./index.css"
import { Link } from "react-router-dom";

function Login(props) {

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    });

    const [errorMessage, setErrorMessage] = useState("")

    const backend_url = process.env.REACT_APP_BACKEND_URL;
    function logIn(event) {
        console.log("logging in")
        console.log(backend_url + "/login")
        axios({
            method: "POST",
            url: backend_url + "/login",
            data: {
                email: loginForm.email,
                password: loginForm.password
            }
        })
            .then((response) => {
                var token = response.data.access_token
                props.setToken(token)
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)

                    if (error.response.statusText === "UNAUTHORIZED") {
                        setErrorMessage("Invalid Username or Password: Try Again")
                    }
                }
            })

        setLoginForm(({
            email: "",
            password: ""
        }))
        event.preventDefault()
    }

    function handleChange(event) {
        const { value, name } = event.target
        setLoginForm(prevNote => ({
            ...prevNote, [name]: value
        })
        )
    }

    function setupListeners() {
        const usernameInput = document.getElementById("usernameInput");
        const passwordInput = document.getElementById("passwordInput")
        
        passwordInput.addEventListener("keypress", (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                document.getElementById("submit-button").click();
            }
        });

        usernameInput.addEventListener("keypress", (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                passwordInput.focus()
            }
        });
    }

    useEffect(setupListeners, [])

    return (
        <div className="login-wrapper">
            <div className="login-box">
                <div className="logo-box">
                    <img src={logo} className="logo" alt="Aquatic Labs Logo"></img>
                </div>
                <div className="form-container">
                    <form className="form-group login">
                        <input onChange={handleChange}
                            id="usernameInput"
                            type="email"
                            text={loginForm.email}
                            name="email"
                            placeholder="email"
                            value={loginForm.email}
                            className="form-control login-item" />
                        <input onChange={handleChange}
                            id="passwordInput"
                            type="password"
                            text={loginForm.password}
                            name="password"
                            placeholder="Password"
                            value={loginForm.password}
                            className="form-control login-item" />
                        <button id="submit-button" type="button" onClick={logIn} className="btn btn-light submit-btn">Submit</button>
                        <div className="error-display">
                            {errorMessage}
                        </div>

                    </form>
                </div>
            </div>

            <div className="login-redirect">
                Need an account? <Link to="/signup">Signup</Link>
            </div>

        </div >

    );
}

export default Login;