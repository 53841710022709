import { useState } from "react";
import axios from "axios";
import logo from "../../res/logo.png"
import "./index.css"
import { Link } from "react-router-dom";


function Tools() {

    return (
        <div>
            <h1>Tools</h1>
        </div>
    );

}

export default Tools;