import "./index.css"

import 'bootstrap/js/dist/dropdown';
import axios from "axios";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function SensorDataDisplay(props) {

    const [value, setValue] = useState(0);
    const [selectedProject, setSelectedProject] = useState("Select Project")

    const setupIntervals = () => {
        const secondInterval = setInterval(() => {
            //hacky and stupid way to force re-render every second for ticking
            setValue(value => value + 1);
        }, 1000);

        return () => {
            clearInterval(secondInterval)
        }
    }

    const getTimeDelta = (t) => {

        const timestamp = new Date(t + " GMT");
        const now = new Date(Date.now())
        const delta = (-1) * (timestamp - now)

        var seconds = delta / 1000
        if (seconds < 60) {  //First day of CS101 ass function 
            seconds = Math.floor(seconds)
            if (seconds === 1) {
                return "1 Second"
            }
            return `${seconds} Seconds`
        }
        var minutes = seconds / 60
        if (minutes < 60) {
            minutes = Math.floor(minutes)
            if (minutes === 1) {
                return `1 Minute`
            }
            return `${minutes} Minutes`
        }
        var hours = minutes / 60
        if (hours < 24) {
            hours = Math.floor(hours)
            if (hours === 1) {
                return `1 Hour`
            }
            return `${hours} Hours`
        }
        var days = Math.floor(hours / 24)
        if (days === 1) {
            return `1 Day`
        }
        else {
            return `${days} Days`
        }
    }

    useEffect(setupIntervals, []);

    return (
        <div className="sensor-data-display-container">
            <div className="sensor-data-header">
                <h4 className="m-0 p-0">Sensor Data</h4>
                <div className="d-flex flex-row align-items-center">
                    <select className="form-select sensor-data-project-select" value={selectedProject} onChange={e => setSelectedProject(e.target.value)}>
                        <option value="select project">Select Project</option>
                        {
                            props.projects.map((p) => {
                                return (
                                    <option vaule={p.project_name}>{p.project_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>

            <div className="sensor-data-body">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ESP #</th>
                            <th scope="col">Uptime</th>
                            <th scope="col">Last Ping</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.sensors
                            .filter((s) => s.project_name === selectedProject)
                            .map((s) => {
                                return (
                                    <tr>
                                        <th><Link to="/data">{s.esp_num}</Link></th>
                                        <td></td>
                                        <td>{getTimeDelta(s.timestamp)}</td>
                                        <td>OK</td>
                                    </tr>

                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default SensorDataDisplay;