import "./index.css"
import DBMap from "./Map/index.js"
import ChartCollection from "./Charts/index.js";
import SensorDataDisplay from "./SensorDataDisplay/index.js";
import { useState, useEffect } from "react";
import axios from "axios";
import ProjectSummary from "./ProjectSummary/index.js";
import StatusBar from "./Status/index.js";
import { FormGroup, Switch, MenuItem, Button, Card, Menu, InputGroup } from "@blueprintjs/core";
import AquaticLoading from "../../UtilComponents/AquaticLoading/index.js";
import { Drawer, Icon, IconSize, Divider } from "@blueprintjs/core";


function DashboardContent(props) {

    const [loaded, setLoaded] = useState(false);
    const [buoys, setBuoys] = useState([]);
    const [selectedBuoy, setSelectedBuoy] = useState();
    const [selectedSources, setSelectedSources] = useState({})
    const [buoyData, setBuoyData] = useState({})
    const [username, setUsername] = useState("Loading...");

    const [projects, setProjects] = useState([]);
    const [sensors, setSensors] = useState([]);

    const drawersInit = {
        alerts: false,
        data: false,
        sensors: false,
        status: false
    }

    const [drawers, setDrawers] = useState(drawersInit)

    function setDrawerActive(drawer) {
        const newDrawers = { ...drawersInit }
        newDrawers[drawer] = true
        setDrawers(newDrawers)
    }

    const properties = {
        jwt: props.jwt,
        username: username,
        buoys: buoys,
        setBuoys: setBuoys,
        selectedSources: selectedSources,
        setSelectedSources, setSelectedSources,
        selectedBuoy: selectedBuoy,
        setSelectedBuoy: setSelectedBuoy,
        buoyData: buoyData,
        setBuoyData: setBuoyData,
        projects: projects,
        setProjects: setProjects,
        sensors: sensors,
        setSensors, setSensors
    }

    const backend_url = process.env.REACT_APP_BACKEND_URL

    const getProjects = () => {
        if (!props.jwt) {
            return;
        }

        axios.get(
            backend_url + "/api/get_projects",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                }
            }
        )
            .then((res) => {
                if (!res) {
                    return
                }
                setProjects(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    const getSensors = () => {
        if (!props.jwt) {
            return
        }
        axios.get(
            backend_url + "/api/get_sensors",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                }
            }
        )
            .then((res) => {
                if (!res) {
                    return
                }
                console.log("sensors", res.data)
                setSensors(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    const setupSensorInterval = () => {
        getSensors()
        const interval = setInterval(() => {
            getSensors()
        }, 60000);

        return () => {
            clearImmediate(interval)
        }
    }

    const getBuoys = async () => {
        axios.get(
            backend_url + "/api/buoys",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                }
            }
        ).catch((error) => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)

                if (error.response.status === 401 && error.response.data.msg === "Token has expired") {
                    console.log("invalid token")
                    props.removeToken()
                }
                return
            }
        }).then((res) => {
            if (res) {
                setBuoys(res.data.buoys)
                setUsername(res.data.username)

                const temp = {}
                res.data.buoys.map((buoy) => temp[buoy.bid] = false)
                setSelectedSources(temp)
            }
        })
    }

    function setup() {
        if (!props.jwt) {
            return
        }
        getBuoys();
        getProjects();
        setupSensorInterval();
        setLoaded(true);
    }

    useEffect(setup, [props.jwt])

    if (!loaded) {
        return (
            <div>hi</div>
        )
    }

    /*
<div className="dashboard-content-container" id="dashboard-content-container">
            <div className="d-flex flex-row justify-content-center gap-2" id="map-data-container">
                <div className="left-cards d-flex flex-column">
                    <div className="d-flex flex-row w-100 h-50">
                        <div className="temp-card-container">
                            <ProjectSummary {...properties} />
                        </div>
                        <DBMap {...properties}></DBMap>
                    </div>
                    <div className="d-flex flex-row w-100 h-50">
                        <div className="temp-card-container">
                            <SensorDataDisplay {...properties} />
                        </div>
                    </div>
                </div>
                <ChartCollection {...properties}></ChartCollection>
            </div>
            <StatusBar></StatusBar>
        </div>
    */

    return (
        <div className="dashboard-content-container" id="dashboard-content-container">
            <DBMap {...properties}></DBMap>
            <div className="dashboard-menu-bar">
                <div
                    className="dashboard-menu-item"
                    onClick={() => setDrawerActive("alerts")}>
                    <div>
                        <Icon icon="warning-sign" size={30} />
                    </div>
                    <div className="dashboard-menu-item-text">Alerts</div>
                </div>
                <Divider></Divider>
                <div
                    className="dashboard-menu-item"
                    onClick={() => setDrawerActive("data")}>
                    <div>
                        <Icon icon="timeline-line-chart" size={30} />
                    </div>
                    <div className="dashboard-menu-item-text">Data</div>
                </div>
                <Divider></Divider>
                <div
                    className="dashboard-menu-item"
                    onClick={() => setDrawerActive("sensors")}>
                    <div>
                        <Icon icon="th-list" size={30} />
                    </div>
                    <div className="dashboard-menu-item-text">Sensors</div>
                </div>
                <Divider></Divider>
                <div 
                className="dashboard-menu-item"
                onClick={() => setDrawerActive("status")}>
                    <div>
                        <Icon icon="console" size={30} />
                    </div>
                    <div className="dashboard-menu-item-text">Status</div>
                </div>
                <Divider></Divider>

            </div>
            <Drawer
                title="Alerts"
                size="40%"
                className="dashboard-drawer"
                icon="warning-sign"
                isOpen={drawers["alerts"]}
                onClose={() => setDrawers(drawersInit)}
                usePortal={false}
                isCloseButtonShown={true}
                hasBackdrop={false}
                canOutsideClickClose={false}
            >
                <ProjectSummary {...properties} />
            </Drawer>
            <Drawer
                title="Data Snapshot"
                className="dashboard-drawer"
                isOpen={drawers["data"]}
                onClose={() => setDrawers(drawersInit)}
                icon="th-list"
                usePortal={false}
                isCloseButtonShown={true}
                hasBackdrop={false}
                canOutsideClickClose={false}
                size="40%"
            >
                <ChartCollection {...properties}></ChartCollection>
            </Drawer>
            <Drawer
                title="Sensors"
                className="dashboard-drawer"
                isOpen={drawers["sensors"]}
                onClose={() => setDrawers(drawersInit)}
                icon="timeline-line-chart"
                usePortal={false}
                isCloseButtonShown={true}
                canOutsideClickClose={false}
                hasBackdrop={false}
                size="40%"
            >
                <SensorDataDisplay {...properties} />
            </Drawer>
            <Drawer
                title="Status Console"
                className="dashboard-drawer"
                isOpen={drawers["status"]}
                onClose={() => setDrawers(drawersInit)}
                icon="timeline-line-chart"
                usePortal={false}
                isCloseButtonShown={true}
                canOutsideClickClose={false}
                hasBackdrop={false}
                size="40%"
            >
                <StatusBar></StatusBar>
            </Drawer>
        </div>
    )
}

export default DashboardContent;

