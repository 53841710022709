import "./index.css";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CardContent from '@mui/material/Card';
import useToken from "../../useToken";
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Grid, Box, Paper, Typography, Card, Select, ButtonBase } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import * as React from 'react';
import axios from "axios";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Stack from '@mui/material/Stack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import UploadIcon from '@mui/icons-material/Upload';
import { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';


const BottomRightBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  position: 'absolute',
  bottom: 16,
  right: 16
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const isRecent = (timestamp) => {
  const now = new Date();
  const updateTime = new Date(timestamp);
  const oneHour = 1000 * 60 * 60; // 1 hour in milliseconds
  return (now - updateTime) <= oneHour;
};

const assignedProjects = (projectMap, sensor) => {
  // Initialize an array to store the results
  const assignedProjects = [];

  // Iterate through projectMap
  
  Object.keys(projectMap).forEach((key) => {
    console.log(projectMap[key]);
    // console.log(typeof projectMap[key][0]); 
    // Check if the sensor is included in the project's assigned sensors
    if (projectMap[key].includes(Number(sensor[0]))) {
      assignedProjects.push(key);
    }
  });

  // Return a string indicating the assigned projects or a default message if none found
  return assignedProjects.length > 0
    ? `${assignedProjects.join(', ')}`
    : 'No project assigned';
};

const SidebarContent = ({ data, sensor, chooseSensor, projectSensorMap, chooseProject, currentProject }) => (
  <React.Fragment>
    
    <CardContent 
      className="dashboard-sidebar"
      style={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
      sx={{ minHeight: '65vh', marginLeft: '15px' }}
    >
      <Paper elevation={0} 
        sx={{ margin: '0px', 
          height: 'auto',
          width:'auto',
          // textAlign: 'center', 
          borderRadius: '1px', marginBottom: '20px',
          display: 'flex', 
          flexDirection: 'column', 
          backgroundColor: '#c9c9c9',
          // flexWrap: 'wrap', 

         }}
      >
        <Stack direction="row" spacing={2} sx={{ padding: '5px', alignItems: 'center', flex:'auto' }}>
          <Typography variant="button" display="block" gutterBottom
            sx={{ margin: '0px', align: "center", padding: '0px', marginRight: '0px',flex: 'auto' }}
          >
             <span style={{fontWeight: 'bold'}}>Controls</span>
          </Typography>
        </Stack>
      </Paper>

      <Paper elevation={5} sx={{ margin: '16px' }}>
        <Box display="flex" justifyContent="flex-end">
          <FormControl fullWidth>
            <InputLabel id="select-project-label">Select Project</InputLabel>
            <Select
              labelId="select-project-label"
              id="select-project"
              label="Select Project"
              onChange={chooseProject}
              value={currentProject}
              renderValue={(value) => value[0]}
            ><MenuItem value="">
              <em>None</em>
            </MenuItem>
              {projectSensorMap && Object.keys(projectSensorMap).map((key) => (
                <MenuItem key={key} value={[key, projectSensorMap[key]]}>
                  {key}
                </MenuItem>
              ))
                
              }

            </Select>
          </FormControl>
        </Box>
      </Paper>

      <Paper elevation={5} sx={{ margin: '16px' }}>
        <Box display="flex" justifyContent="flex-end">
          
          <FormControl fullWidth disabled={!currentProject}>
            <InputLabel id="select-sensor-label">Select Sensor</InputLabel>
            <Select
              labelId="select-sensor-label"
              id="select-sensor"
              label="Select Sensor"
              onChange={chooseSensor}
              value={sensor}
              renderValue={(value) => value[0]}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {currentProject && Object.values(currentProject[1]).map((key) => (
                <MenuItem key={key} value={[key, data[key]]}>
                  {'Sensor ' + key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>
    </CardContent>
  </React.Fragment>
);

const SensorStatusCard = ({ data, sensor , projects, projectMap}) => (
  <React.Fragment>
    <CardContent
      style={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
      sx={{ minHeight: '80vh', marginRight: '15px' }}
    >
      <Paper
        elevation={2}
        sx={{ margin: '14px', padding: '30px', textAlign: 'center', borderRadius: '5px', marginBottom: '25px' }}
      >
        <p style={{ textAlign: 'left', flex: 1, flexDirection: 'row' }}>
          <Box  sx={{ border: 1, borderWidth: '2px',
          borderRadius: '5px', bgcolor: '#283747', 
            padding:'10px',}}>
        <h3 style={{color:'white'}}>
          Sensor <span style={{fontWeight:'bold'}}> {sensor[0]} </span>
        </h3> 
        </Box>
          <List> 

          <ListItem>
          Latest Update: &nbsp;  <span style={{fontWeight: 'bold'}}>{sensor[1]}</span>
          </ListItem>
          <ListItem>
          Status: &nbsp; <Chip
          label={isRecent(sensor[1]) ? 'Active' : 'Not Active'}
                  sx={{
                    backgroundColor: isRecent(sensor[1]) ? '#1ab170' : '#FF5733',
                    color: 'white',
                    borderRadius: '5px',
                  }}>
            
             </Chip>
          </ListItem>
          <ListItem>
          Deployed Location:
          </ListItem>
          <ListItem>
            Project:  &nbsp; <span >{assignedProjects(projectMap, sensor)}</span>
            </ListItem>
            <ListItem>
              Last Calibration:
              </ListItem>
          </List>

        </p>

      </Paper>
    </CardContent>
  </React.Fragment>
);

const BlankCard = () => (
  <React.Fragment>
     <CardContent
      style={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
      sx={{ minHeight: '80vh', marginRight: '15px' }}>
         <Paper
        elevation={2}
        sx={{ margin: '14px', padding: '30px', textAlign: 'center', borderRadius: '5px', marginBottom: '25px', bgcolor:'#449c76', }}>
           <p style={{ textAlign: 'left', flex: 1, flexDirection: 'row', }}>
            {/* <Box  sx={{ border: 0, bgcolor:'green', padding:'10px', textAlign:'center', borderRadius:'5px'}}> */}
        <h4 style={{color:'white', textAlign:'center'}}>
          Choose a project to view available sensors - <span style={{fontWeight:'bold'}}>PAGE UNDER CONSTRUCTION</span>
        </h4> 
        {/* </Box> */}
        </p>
        </Paper>
      </CardContent>
  </React.Fragment>

);

const AvailableSensorsCard = ({ currentProject, chooseSensor, sensor, data}) => ( 
  <React.Fragment>
    <CardContent
    style={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
    sx={{ minHeight: '80vh', marginRight: '15px' }}>
       <Paper
        elevation={2}
        sx={{ margin: '14px', padding: '30px', textAlign: 'center', borderRadius: '5px', marginBottom: '25px' }}>
           <p style={{ textAlign: 'left', flex: 1, flexDirection: 'row' }}>
        <h3 >
          Current Sensors for Project: {currentProject[0]} <Chip label="Active" sx={{backgroundColor: '#1ab170', color: 'white', borderRadius: '5px',}} />
        &nbsp;
        <Chip label="Inactive" sx={{backgroundColor: '#FF5733', color: 'white', borderRadius: '5px',}} />
        </h3> 

        </p>
        <Grid container spacing={1} sx={{margin:"1px"}}>
          {currentProject && Object.values(currentProject[1]).map((key) => (
            <Grid item xs={1}>
              
              <ButtonBase xs={1} onClick={() => chooseSensor({ target: { value: [key, data[key]] } })}>
              <Item sx={{
                backgroundColor: isRecent(data[key]) ? '#1ab170' : '#FF5733',
              }}>
                
                 <span style={{fontWeight:'bold'}}>{'Sensor ' + key} </span></Item>
              </ButtonBase>
            </Grid>

          ))}
        </Grid>
        {/* <Button onClick={() => { alert('clicked') }}>Click me</Button> */}
      </Paper>
    </CardContent>
  
  </React.Fragment>
);


function DataManagement() {
  const { token, removeToken, setToken } = useToken();
  const [username, setUsername] = useState("Loading...");
  const [selectedSources, setSelectedSources] = useState({});


  const backend_url = process.env.REACT_APP_BACKEND_URL;
  const [sensor, setSensor] = React.useState('');
  const [latestDate, setLatestData] = React.useState('');
  const [projects, setProjects] = React.useState('');
  const [projectSensorMap, setProjectSensorMap] = React.useState({});
  const [currentProject,setCurrentProject] = React.useState('');

  const chooseSensor = (event) => {
    setSensor(event.target.value);
    
    console.log(sensor);
 
  };

  const chooseProject = (event) => {
  setCurrentProject(event.target.value);
  setSensor('');
  console.log('choosing current project:');
  console.log(currentProject);
  console.log(sensor);

  };

const resetSelections = () => {
    setCurrentProject('');
    setSensor('');
  };

  const getData = async () => {
    console.log('Fetching data...');
    try {
      const response = await axios.get(`${backend_url}/api/latest_sensor_status`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const sensorMetaData = response.data.reduce((acc, sensor) => {
        acc[sensor.esp_num] = sensor.timestamp;
        return acc;
      }, {});
      setSelectedSources(sensorMetaData);
      console.log('Data loaded successfully.');
    } catch (error) {
      if (error.response) {
        console.error('Error:', error.response);
        if (error.response.status === 401 && error.response.data.msg === "Token has expired") {
          console.log("Invalid token");
          // removeToken();
        }
      }
    }
  };

  const sortedSensors = (projects, sensors) => {
    console.log('Sorting projects with sensors...');
    // console.log(sensors[0]);
    const projectSensorMap = {};
    projects && Object.keys(projects).map((key) => {
      console.log(projects[key]);
      projectSensorMap[projects[key].project_name] = projects[key].assigned_sensors;
    });
    console.log(projectSensorMap);
    
    return projectSensorMap;
  };
  
  const individualSensorStatus = (sensor) => {
    console.log(sensor[1]);
  }


  const getProjectData = async () => {
    console.log('Fetching project data...');
  try {
    const response = await axios.get(`${backend_url}/api/get_projects`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setProjects(response.data);
    // console.log(response.data);
    console.log('Projects loaded successfully.');
  } catch (error) {
    if (error.response) {
      console.error('Error:', error.response);
      if (error.response.status === 401 && error.response.data.msg === "Token has expired") {
        console.log("Invalid token");
        // removeToken();
      }
    }
  }
    // const sortedProjects = sortedSensors(projects, selectedSources);
};

useEffect(() => {
    getData();
    getProjectData();
  }, [token]);

  useEffect(() => {
    if (projects && Object.keys(selectedSources).length > 0) {
      const map = sortedSensors(projects, selectedSources);
      setProjectSensorMap(map);
    }
  }, [projects, selectedSources]);
{/* A JSX comment */}

  return (
    <Box sx={{ flexGrow: 1, margin:'8px' }}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          {console.log('selectedSources')}
          {console.log(projectSensorMap)}
          <SidebarContent data={selectedSources} sensor={sensor}
           chooseSensor={chooseSensor} projectSensorMap={projectSensorMap} 
           chooseProject={chooseProject} currentProject={currentProject}
           />
        </Grid>
        <Grid item xs={9}>
          {currentProject ?
          sensor ? 
          <SensorStatusCard data={selectedSources} sensor={sensor} projects={projects} projectMap={projectSensorMap}/>
          : <AvailableSensorsCard currentProject={currentProject} chooseSensor={chooseSensor} sensor={sensor} data={selectedSources} />
          : <BlankCard  />
}
        </Grid>
      </Grid>
      <BottomRightBox
      >
        <Button
          variant="contained"
          sx={{ borderRadius: '15px' }}
          style={{ minHeight: '30px', minWidth: '30px' }}
          onClick={resetSelections}
        >
          <RestartAltIcon />
        </Button>
      </BottomRightBox>
    </Box>
  );
}

export default DataManagement;
