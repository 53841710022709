import "./index.css"
import MousePosition from 'ol/control/MousePosition.js';
import { createStringXY } from 'ol/coordinate.js';
import { apply } from 'ol-mapbox-style';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj.js';
import { useEffect, useState } from "react";
import VectorLayer from "ol/layer/Vector.js"
import VectorSource from "ol/source/Vector.js"
import Feature from "ol/Feature.js"
import Point from 'ol/geom/Point.js';
import { Circle, Fill, Stroke, Style } from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import { Popover } from "bootstrap";

const mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(4),
    projection: 'EPSG:4326',
});
const mapStyle = "https://api.maptiler.com/maps/3988e948-a441-47c9-8696-b97a768b3428/style.json?key=o1DgOHVjzyouAuj4X3A1"

const fill = new Fill({
    color: 'rgba(255,255,255,0.4)',
});
const stroke = new Stroke({
    color: '#3399CC',
    width: 1.25,
});
const highlightStyle = new Style({
    image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 8
    })
})

function DBMap(props) {

    const [featuresLayer, setFeaturesLayer] = useState()
    const [map, setMap] = useState()
    const [popupOverlay, setPopupOverlay] = useState()

    const generateFeatures = () => {
        if (props.buoys) {
            const newFeatures = props.buoys.length === 0 ? [] : props.buoys.map((b) => {
                //make new feature
                let newFeature = new Feature({
                    geometry: new Point(fromLonLat([b.lon, b.lat])),
                    name: b.name,
                    id: b.bid
                })

                //change style from deafult if it's highlighted
                if (props.selectedSources[b.bid]) {
                    newFeature.setStyle(highlightStyle)
                }
                return newFeature;
            })
            return newFeatures
        }
        return []
    }

    var onClick = function (evt) {
        const pixel = map.getEventPixel(evt.originalEvent);
        if (featuresLayer) {
            featuresLayer.getFeatures(pixel)
                .then(function (features) {
                    const feature = features.length ? features[0] : undefined;
                    if (feature) {
                        const newSelectedBuoy = props.buoys.find((b) => b.name === feature.values_.name)

                        if (newSelectedBuoy) {
                            var temp = { ...props.selectedSources }
                            temp[newSelectedBuoy.bid] = !temp[newSelectedBuoy.bid]
                            props.setSelectedSources(temp)
                        }
                    }
                })
        }
    }

    var onPointerMove = function (evt) {
        if (evt.dragging) {
            return;
        }
        const pixel = map.getEventPixel(evt.originalEvent);
        if (featuresLayer) {
            featuresLayer.getFeatures(pixel)
                .then(function (features) {
                    const feature = features.length ? features[0] : undefined;

                    const element = popupOverlay.element;
                    const coordinate = evt.coordinate;
                    popupOverlay.setPosition(coordinate);
                    let popover = Popover.getInstance(element);
                    if (popover) {
                        popover.dispose();
                    }

                    if (feature) {
                        const newSelectedBuoy = props.buoys.find((b) => b.name === feature.values_.name)
                        if (newSelectedBuoy) {
                            const content =
                                `
                            <div>
                                <div>Lon: ${Math.round(newSelectedBuoy.lon * 100) / 100}</div>
                                <div>Lat: ${Math.round(newSelectedBuoy.lat * 100) / 100}</div>
                            </div >
                            `
                            popover = new Popover(element, {
                                animation: false,
                                container: element,
                                content: content,
                                html: true,
                                placement: 'top',
                                title: newSelectedBuoy.name,
                            });
                            popover.show();
                        }

                    }
                })
        }


    }

    const onBuoyChange = () => {
        if (featuresLayer && props.buoys) {
            const newFeatures = generateFeatures()
            featuresLayer.setSource(
                new VectorSource({
                    features: newFeatures
                })
            )
        }
    }

    var loadMap = () => {

        /*
        const features = generateFeatures();
        const featuresLayerInit = new VectorLayer({
            source: new VectorSource({
                features: features
            })
        })
        setFeaturesLayer(featuresLayerInit)
        */

        const mapInit = new Map({
            controls: [mousePositionControl],
            target: "map",
            view: new View({
                center: fromLonLat([-69.5, 42.2]),
                zoom: 7,
            }),
        });

        /*
        const popup = new Overlay({
            element: document.getElementById('popup'),
        });
        mapInit.addOverlay(popup);
        setPopupOverlay(popup)
        */

        apply(mapInit, mapStyle)
        setMap(mapInit)

        return () => {
            mapInit.setTarget(null)
        }
    }

    var addMapListener = function () {
        if (map) {
            /*
            Was having issues with howeving over map features not updating on the map or 
            the state variables, and on inspection it seemed that props was frozen at the
            state at the time the callback function was created, as opposed to the current
            value of props. This seems to be a strange interaction between openlayers event
            listening and react's state/props, as normally react does not have deep binding
            in this way (state getters at least should be static)
     
            This is a hacky workaround where we reset the callback function on buoy data
            changing, which gives the function access to the proper getter/setters as 
            efined in the global state variables. 
            */
            map.un('click', onClick);
            map.on('click', onClick);
            map.un('pointermove', onPointerMove)
            map.on('pointermove', onPointerMove)
        }
    }

    useEffect(loadMap, []);
    //useEffect(addMapListener, [map, props.buoys, props.selectedSources]);
    //useEffect(onBuoyChange, [props.buoys, props.selectedSources])

    return (
        <div className="map-outer-container">
            <div className="map-box">
                <div id="map"></div>
                <div>
                    <div className="attribution">© MapTiler © OSM contributors</div>
                    <div id="mouse-position"></div>
                    <div id="popup"></div>
                </div>
            </div>

        </div>
    )
}

export default DBMap  