import "./index.css"

import axios from "axios"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const EditProfile = (props) => {
    const backend_url = process.env.REACT_APP_BACKEND_URL;

    const userData = props.userData
    const [file, setFile] = useState(null)
    const saveCompany = () => {

    }

    const saveUser = () => {

    }

    const uploadNewPfp = (event) => {
        event.preventDefault();
        const formdata = new FormData();
        formdata.append('file', file)
        formdata.append('test', props["company_name"] + "/images/" + file.name)
        const config = {
            headers: {
                Authorization: "Bearer " + props.jwt,
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(backend_url + "/upload-pfp", formdata, config)
            .then((res) => {
                console.log(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (userData["admin"]) {
        return (
            <div className="p-2">
                <div className="d-flex flex-row align-content-center gap-3">
                    <div className="d-flex flex-column">
                        <img src="" className="profile-image-container"></img>
                        <form>
                            <input type="file" onChange={(event) => setFile(event.target.files[0])} />
                            <button onClick={uploadNewPfp}>Upload</button>
                        </form>
                    </div>

                    <div className="d-flex flex-column gap-1">
                    </div>
                    <div>
                        <div className="d-flex flex-row gap-2 align-items-baseline">
                            <h3>Edit Company Profile</h3>
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Company Name: </b>
                            {userData["company_name"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Contact: </b>
                            {userData["contact"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Description: </b>
                            {userData["customer_notes"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Users: </b>
                            <div className="profile-users-container">
                                {userData["users"].map((user) => {
                                    return (
                                        <div>
                                            {user.is_admin ? user.email + " (admin)" : user.email}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div className="d-flex flex-row align-content-center gap-3">
                    <img src="" className="profile-image-container"></img>
                    <div className="d-flex flex-column gap-1">
                    </div>
                    <div>
                        <div className="d-flex flex-row gap-2 align-items-baseline">
                            <h3>User Profile</h3>
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Email: </b>
                            {userData["user-email"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Password: </b>
                            <button>change password</button>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }
    else {
        return (
            <div className="p-2">
                <div className="d-flex flex-row align-content-center justify-content-between">
                    <h3>Non-Admin Company Profile</h3>
                    <button className="btn btn-secondary">Edit Company Profile</button>
                </div>
                <div className="d-flex flex-column">
                    <img src="" className="profile-image-container"></img>
                    <div>Company Name</div>
                    <div>Company ID</div>
                </div>
                <hr />
                <div className="d-flex flex-row align-content-center justify-content-between">
                    <h3>User Profile</h3>
                    <button className="btn btn-secondary">Edit User Profile</button>
                </div>
                <div>Username</div>
                <div>User-Type</div>
                <hr />
            </div>
        )
    }
}

export default EditProfile 