import "./index.css"

import axios from "axios"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EditProfile from "./Edit";
import AquaticLoading from "../../UtilComponents/AquaticLoading"
const Profile = (props) => {
    

    const backend_url = process.env.REACT_APP_BACKEND_URL;

    const [userData, setUserData] = useState(null)
    const [editing, setEditing] = useState(false)
    const [companyPfp, setCompanyPfp] = useState("")

    const getUserData = () => {
        axios.get(
            backend_url + "/load-profile",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                }
            }
        )
            .then((res) => {
                console.log(res.data)
                setUserData(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    useEffect(getUserData, []);

    if (!userData) {
        return (
            <div className="profile-page-loading-container">
                <AquaticLoading/>
            </div>
        )
    }

    else if (editing) {
        return <EditProfile userData={userData} jwt={props.jwt}/>
    }

    else if (userData["admin"]) {
        return (
            <div className="p-2">
                <div className="d-flex flex-row align-content-center gap-3">
                    <img src={backend_url + "/get-company-pfp/" + userData["company_name"]} className="profile-image-container"></img>
                    <div className="d-flex flex-column gap-1">
                    </div>
                    <div>
                        <div className="d-flex flex-row gap-2 align-items-baseline">
                            <h3>Company Profile: Administrator View </h3>
                            <button onClick={() => { setEditing(true) }}>Edit Profile</button>
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Company ID: </b>
                            <span className="company_id_container">{userData["company_id"]}</span>

                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Company Name: </b>
                            {userData["company_name"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Contact: </b>
                            {userData["contact"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Description: </b>
                            {userData["customer_notes"]}
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Users: </b>
                            <div className="profile-users-container">
                                {userData["users"].map((user) => {
                                    return (
                                        <div key={user.id}>
                                            {user.is_admin ? user.email + " (admin)" : user.email}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <div className="d-flex flex-row align-content-center gap-3">
                    <img src="" className="profile-image-container"></img>
                    <div className="d-flex flex-column gap-1">
                    </div>
                    <div>
                        <div className="d-flex flex-row gap-2 align-items-baseline">
                            <h3>User Profile</h3>
                            <button onClick={() => { setEditing(true) }}>Edit Profile</button>
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-baseline">
                            <b className="profile-field-title">Email: </b>
                            {userData["user-email"]}
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }
    else {
        return (
            <div className="p-2">
                <div className="d-flex flex-row align-content-center justify-content-between">
                    <h3>Non-Admin Company Profile</h3>
                    <button className="btn btn-secondary">Edit Company Profile</button>
                </div>
                <div className="d-flex flex-column">
                    <img src="" className="profile-image-container"></img>
                    <div>Company Name</div>
                    <div>Company ID</div>
                </div>
                <hr />
                <div className="d-flex flex-row align-content-center justify-content-between">
                    <h3>User Profile</h3>
                    <button className="btn btn-secondary">Edit User Profile</button>
                </div>
                <div>Username</div>
                <div>User-Type</div>
                <button onClick={getUserData}>hi</button>
                <hr />
            </div>
        )
    }
}

export default Profile 