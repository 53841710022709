import "./index.css"

const NotFound = () => {
    return(
        <div className="notfound">
            <h1>404: Page Not Found</h1>
        </div>
    )
}

export default NotFound