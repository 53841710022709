import { useState } from 'react';

/**
 * Handles local storage of tokens
 */
function useToken() {

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  //saves token, used for loggin in
  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  //deletes token, used for logging out
  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  return {
    setToken: saveToken,
    token,
    removeToken
  }

}

export default useToken;
