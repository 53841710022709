import "./index.css"

import { Card } from "@blueprintjs/core";


function ProjectSummary(props) {
    return (
        <Card className="project-summary-container">
            Alerts and such here
        </Card>
    )
}

export default ProjectSummary;