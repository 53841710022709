import "./index.css"

import AquaticLoading from "../../UtilComponents/AquaticLoading";
import Pco2Graph from "./Graphs/Pco2Graph";
import PressureGraph from "./Graphs/PressureGraph";
import TemperatureGraph from "./Graphs/TemperatureGraph";

import axios from "axios";
import { useEffect, useState } from "react";


function Details(props) {

    const backend_url = process.env.REACT_APP_BACKEND_URL
    const [projects, setProjects] = useState(null)
    const [sensors, setSensors] = useState(null)

    const [detailsLoading, setDetailsLoading] = useState(true)

    const getProjects = () => {
        if (!props.jwt) {
            return;
        }

        axios.get(
            backend_url + "/api/get_projects",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                },
            }
        )
            .then((res) => {
                console.log(res.data)
                setProjects(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    const getSensors = () => {
        if (!props.jwt) {
            return
        }
        axios.get(
            backend_url + "/api/get_sensors",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                }
            }
        )
            .then((res) => {
                console.log("sensors", res.data)
                setSensors(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    useEffect(getProjects, [])
    useEffect(getSensors, [])

    function checkLoaded() {
        if (!projects) {
            return
        }
        setDetailsLoading(false)
    }

    useEffect(checkLoaded, [projects])

    if (detailsLoading) {
        return (
            <div className="details-loading">
                <AquaticLoading />
            </div>
        )
    }

    return (
        <div>
            <Pco2Graph
                jwt={props.jwt}
                title="Pco2 Data"
                projects={[...projects]}
            />
            <PressureGraph
                jwt={props.jwt}
                title="Pressure Data"
                projects={[...projects]}
            />
            <TemperatureGraph
                jwt={props.jwt}
                title="Temperature Data"
                projects={[...projects]}
            />
        </div>
    )
}

export default Details;