import axios from "axios";
import "./index.css"
import logo from "../res/logo.png"
import { Link } from "react-router-dom";

/**
 * Header at top of all pages, contains logout button (and maybe more in the future)
 */
function Header(props) {

    const backend_url = process.env.REACT_APP_BACKEND_URL

    function logOut() {
        axios({
            method: "POST",
            baseURL: backend_url,
            url: backend_url + "/logout"
        })
            .then((resp) => {
                props.removeToken()
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            })
    }

    return (
        <div className="w-100">

            <div className="header-bar" id="dashboard-header">
                <img src={logo} className="logo" alt="Aquatic Labs Logo" />
                <Link className="btn header-button" to="/" >Dashboard</Link>
                <Link className="btn header-button" to="/details" >Details</Link>
                <Link to="/profile" className="btn header-button">Profile</Link>
                <Link to="/data" className="btn header-button">Sensor Management</Link>
                <Link to="/upload" className="btn header-button">Upload Data</Link>
                <Link to="/tools" className="btn header-button">Tools</Link>
                {/* <Link to="/projectManager" className="btn header-button">Project Management</Link> */}
                <button type="button" onClick={logOut} className="btn logout-button">Logout</button>
            </div>
        </div>
    )
}

export default Header;