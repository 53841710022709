import Chart from "chart.js/auto"
import "chartjs-adapter-date-fns"
import { useState, useEffect } from "react"
import axios from "axios"
import "./index.css"
import { Card, Collapse } from "@blueprintjs/core";


const ChartCollection = (props) => {

    const [displaySelection, setDisplaySelection] = useState("last-week")
    const [parameters, setParameters] = useState({})
    const [selectedSensor, setSelectedSensor] = useState();
    const [chosenProject, setChosenProject] = useState("co2")
    const [tideData, setTideData] = useState([])
    const [tidePredictionData, setTidePredictionData] = useState([])
    const backend_url = process.env.REACT_APP_BACKEND_URL

    const padLeading0 = (num) => {
        return num > 9 ? "" + num : "0" + num
    }

    const loadBuoyData = () => {
        if (!props.buoys || props.buoys.length === 0) {
            return
        }

        if (props.sensors.length == 0) {
            return
        }

        const now = Math.floor(Date.now() / 1000)
        const weekAgo = now - 604800 //7 x 24 x 60 x 60 = 604800 seconds

        var esp_nums = ""
        props.sensors.map((sensor, i) => i === 0 ? esp_nums = esp_nums + sensor.esp_num : esp_nums = esp_nums + "," + sensor.esp_num);

        axios.get(
            backend_url + "/api/buoy-data",
            {
                headers: {
                    Authorization: "Bearer " + props.jwt
                },
                params: {
                    bids: esp_nums,
                    end: now,
                    start: weekAgo
                },
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
                else {
                    console.log("no response when getting buoy data")
                }
                return
            })
            .then((res) => {
                if (res) {
                    console.log("got buoy data")
                    props.setBuoyData(res.data)
                    console.log(res.data)
                }
            })


        axios.get(backend_url + "/api/tide-data",
            {
                params: {
                    start: weekAgo,
                    end: now
                }
            }
        )
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
                return
            })
            .then((res) => {
                if(res) {
                    setTideData(res.data)
                }
            })

        axios.get(backend_url + "/api/tide-prediction",
            {
                params: {
                    start: weekAgo,
                    end: now
                }
            }
        )
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
                return
            })
            .then((res) => {
                setTidePredictionData(res.data)
            })
    }

    const onChartOptionsChange = () => {
        if (!props.buoyData || Object.keys(props.buoyData).length === 0 || parameters.length === 0) {
            return
        }

        const container = document.getElementById("charts-container")
        console.log("starting to build chart")
        while (container.firstChild) {
            console.log("removing")
            container.removeChild(container.lastChild)
        }

        const newChart = container.appendChild(document.createElement("div"))
        const canvas = newChart.appendChild(document.createElement("canvas"))
        const chartCtx = canvas.getContext("2d")
        newChart.classList.add("chart-container")

        const tideChart = container.appendChild(document.createElement("div"))
        const tideCanvas = tideChart.appendChild(document.createElement("canvas"))
        const tideCtx = tideCanvas.getContext("2d")
        tideChart.classList.add("tide-container")

        const tideDatasets = []
        const chartDatasets = []

        const data = props.buoyData[selectedSensor]
        if (data) {
            const formattedData = data.timestamps.map((t, i) => { return { x: t, y: data.vals[i] } })
            chartDatasets.push({
                label: "co2",
                data: [...formattedData]
            })
        }

        if (tideData) {
            tideDatasets.push({
                label: "Recorded Tide Height",
                data: tideData,
                fill: true
            })
        }
        if (tidePredictionData) {
            tideDatasets.push({
                label: "Projected Tide Height",
                data: tidePredictionData
            })
        }

        //date window end and start
        const dwe = new Date(Date.now())
        let dws;
        switch (displaySelection) {
            case "last-week":
                dws = new Date(Date.now() - 604800000)
                break;
            case "last-3-days":
                dws = new Date(Date.now() - 259200000)
                break;
            case "last-24-hours":
                dws = new Date(Date.now() - 86400000)
                break;
            default:
                dws = new Date(Date.now() - 604800000)
                break;
        }

        const x_max = `${dwe.getFullYear()}-${padLeading0(dwe.getMonth() + 1)}-${padLeading0(dwe.getDate())} ${dwe.getHours()}:${padLeading0(dwe.getMinutes())}`
        const x_min = `${dws.getFullYear()}-${padLeading0(dws.getMonth() + 1)}-${padLeading0(dws.getDate())} ${dws.getHours()}:${padLeading0(dws.getMinutes())}`

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            borderWidth: 2,
            animation: {
                duration: 0
            },
            plugins: {
                title: {
                    display: true,
                    text: chosenProject
                },
                legend: {
                    display: true
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            stacked: false,
            scales: {
                x: {
                    type: "time",
                    min: x_min,
                    max: x_max
                },
                y: {
                    type: 'linear',
                    title: {
                        display: true,
                        text: "units here"
                    }
                }
            }
        }

        new Chart(
            tideCtx,
            {
                type: 'line',
                data: {
                    datasets: tideDatasets
                },
                options: {
                    ...chartOptions,
                    plugins: {
                        ...chartOptions.plugins,
                        title: {
                            ...chartOptions.plugins.title,
                            text: "Tides"
                        }
                    },
                    scales: {
                        ...chartOptions.scales,
                        y: {
                            type: 'linear',
                            title: {
                                display: true,
                                text: "m above MLLW"
                            }
                        }
                    }
                }
            }
        )
        new Chart(
            chartCtx,
            {
                type: 'line',
                data: {
                    datasets: chartDatasets //for namespace shenagans, do not name "datasets" lol 
                },
                options: chartOptions
            }
        )
    }

    const handleProjectClick = (evt) => {
        console.log(evt.target.value)
        const temp = { ...parameters }
        temp[chosenProject] = false;
        temp[evt.target.value] = true;
        setParameters(temp)
        setChosenProject(evt.target.value)
    }

    useEffect(loadBuoyData, [props.sensors])
    useEffect(onChartOptionsChange, [props.selectedSources, displaySelection, selectedSensor, tideData, tidePredictionData])

    return (
        <div className="charts-info-wrapper d-flex flex-column">
            <div className="snapshot-charts-header">
                <div className="chart-header-title">
                    <h4 className="m-0">Options</h4>
                </div>
                <div className="data-snapshot-options-container p-2">
                    <div className="input-group m-1">
                        <div className="input-group-prepend">
                            <span class="input-group-text">Display</span>
                        </div>
                        <select className="form-select" value={displaySelection} onChange={e => setDisplaySelection(e.target.value)}>
                            <option value="last-week">Last Week</option>
                            <option value="last-3-days">Last 3 Days</option>
                            <option value="last-24-hours">Last 24 Hours</option>
                        </select>
                    </div>
                    <div className="input-group m-1">
                        <div className="input-group-prepend">
                            <span class="input-group-text">Project</span>
                        </div>
                        <select className="form-select" value={chosenProject} onChange={handleProjectClick}>
                            <option value="">Select Project</option>
                            {
                                props.projects.map((p) => {
                                    return (
                                        <option vaule={p.project_name}>{p.project_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="input-group m-1">
                        <div className="input-group-prepend">
                            <span class="input-group-text">Sensors</span>
                        </div>
                        <select value={selectedSensor} onChange={((e) => setSelectedSensor(e.target.value))} className="form-select">
                            <option value="select project">Select Sensor</option>
                            {props.sensors.filter((s) => s.project_name === chosenProject)
                                .map((s) => {
                                    return (
                                        <option value={s.esp_num}>{s.esp_num}</option>
                                    )
                                })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="charts-container-wrapper">
                <div className="charts-container" id="charts-container">
                    <div className="chart-container"></div>
                    <div className="tide-container"></div>
                </div>
            </div>
        </div >

    )
}

export default ChartCollection
