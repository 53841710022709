import { useState } from "react";
import axios from "axios";
import logo from "../../res/logo.png"
import "./index.css"
import { Link } from "react-router-dom";

function Signup(props) {

    const [loginForm, setLoginForm] = useState({
        email: "",
        companyId: "",
        password: "",
        confirmPassword: ""
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const backend_url = process.env.REACT_APP_BACKEND_URL;

    function signUp(event) {
        event.preventDefault();
        if (loginForm.companyId === "") {
            setErrorMessage("Company ID is a required field")
            return
        }

        if (loginForm.email === "") {
            setErrorMessage("Email is a required field")
            return
        }

        if (loginForm.password === "") {
            setErrorMessage("Password is a required field")
            return
        }

        if (loginForm.confirmPassword === "") {
            setErrorMessage("Confirm Password is a required field")
            return
        }

        if (loginForm.password !== loginForm.confirmPassword) {
            setErrorMessage("Passwords do not match")
            return
        }

        console.log("sending signup request")
        console.log(loginForm)
        axios({
            method: "POST",
            url: backend_url + "/signup",
            data: {
                companyId: loginForm.companyId,
                email: loginForm.email,
                password: loginForm.password,
                confirmPassword: loginForm.confirmPassword,
            }
        })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.headers)

                    setErrorMessage(error.response.data)
                }
            })
            .then((res) => {
                console.log("got back")
                console.log(res)
                if (!res) {
                    return;
                }

                if (res.status === 200 && res.data === "user successfully added to database") {
                    setErrorMessage("")
                    setSuccess(true);
                }
            })
    }

    function handleChange(event) {
        const { value, name } = event.target
        setLoginForm(prevForm => ({
            ...prevForm, [name]: value
        })
        )
    }

    if (success) {
        return (
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="logo-box">
                        <img src={logo} className="logo" alt="Aquatic Labs Logo"></img>
                    </div>
                    <div className="form-container">
                        Account successfully created, you may now <Link to="/login">log in</Link>
                    </div>
                </div>
            </div >
        )
    }

    else {
        return (
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="logo-box">
                        <img src={logo} className="logo" alt="Aquatic Labs Logo"></img>
                    </div>
                    <div className="form-container">
                        <form className="form-group login">
                            <input onChange={handleChange}
                                name="companyId"
                                text={loginForm.companyId}
                                placeholder="company ID"
                                value={loginForm.companyId}
                                className="form-control login-item"
                                required />
                            <input onChange={handleChange}
                                type="email"
                                text={loginForm.email}
                                name="email"
                                placeholder="email"
                                value={loginForm.email}
                                className="form-control login-item"
                                required />
                            <input onChange={handleChange}
                                type="password"
                                text={loginForm.password}
                                name="password"
                                placeholder="password"
                                value={loginForm.password}
                                className="form-control login-item"
                                required />
                            <input onChange={handleChange}
                                type="password"
                                text={loginForm.confirmPassword}
                                name="confirmPassword"
                                placeholder="confirm password"
                                value={loginForm.confirmPassword}
                                className="form-control login-item"
                                required />
                            <button onClick={signUp} className="btn btn-light submit-btn">Create Account</button>
                        </form>
                    </div>
                </div>
                <div className="error-display">
                    {errorMessage}
                </div>
                <div className="login-redirect">
                    Back to <Link to="/">Login</Link>
                </div>
                <i>Note: In production this page would probably only be accessible via special link
                    that is sent to each client, in order to not allow anyone with access to the
                    signup page to make an account.</i>
            </div >
        );
    }
}

export default Signup;