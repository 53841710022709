import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import DashboardContent from "./Routes/DashboardContent";
import UploadPage from "./Routes/UploadPage";
import Details from "./Routes/Details";
import Login from "./Routes/Login";
import Signup from "./Routes/Signup"
import useToken from "./useToken";
import Header from "./Header"
import NotFound from "./Routes/NotFound";
import Profile from "./Routes/Profile";
import Tools from "./Routes/Tools";
import DataManagement from "./Routes/DataManagement";
import { useEffect } from "react";

function App() {

  const { token, removeToken, setToken } = useToken();


  useEffect(() => {
    console.log("once")
  }, [])


  if (!token) {
    return <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Login setToken={setToken}></Login>} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </BrowserRouter>
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Header removeToken={removeToken} />
        <Routes>
          <Route path="/" element={<DashboardContent jwt={token} removeToken={removeToken} />} />
          <Route path="/details" element={<Details jwt={token}/>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/profile" element={<Profile jwt={token}/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/data" element={<DataManagement to="/" />} />
          <Route path="/upload" element={<UploadPage to="/" />} />
          <Route path="/tools" element={<Tools to="/"/>} />
          {/* <Route path="/projectManager" element={<InternalProjectManagement to="/" />} /> */}
          {/* <Route path="/uploadData" element={<uploadData />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
